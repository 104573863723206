
  import { Component, Prop } from 'vue-property-decorator'
  import { mapGetters } from 'vuex'
  import { Category, Inspection, InspectionView } from '@/entities/purchase'
  import { fixPrice } from '@/utils/general'
  import RatingCell from '@/components/dataTables/cell/RatingCell.vue'
  import { GForm } from '@/components/forms/GForm'
  import InspectionPdf from '@/components/forms/inspection/report/InspectionPDF.vue'

@Component({
  components: { RatingCell, InspectionPdf },
  methods: { fixPrice },
  computed: {
    ...mapGetters('resources/form', ['backup']),
  },
})
  export default class CategorySummary extends GForm {
  @Prop() value
  @Prop({ default: 'Reporte de inspección', type: String }) title: string
  @Prop({ default: false, type: Boolean }) showTitle: boolean
  @Prop({ default: true, type: Boolean }) showPdfButton: boolean
  @Prop({ default: false, type: Boolean }) hideComments: boolean
  backup!: Record<string, any>
  displayConsignment = false

  async mounted () {
    const process = (await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: {
        table_name: { _eq: 'purchase_order' },
      },
    }))[0]

    this.displayConsignment = process?.config?.displayConsignment
  }

  get inspection () {
    const { backup, value } = this

    if (backup?.inspection !== null && backup?.inspection !== undefined) {
      return backup?.inspection as Inspection
    } else if (value?.inspection !== null && value?.inspection !== undefined) {
      return value?.inspection as Inspection
    }
    return (value?.inspectionView?.[0] || backup?.inspectionView?.[0]) as InspectionView
  }

  get inspectionMetadata () {
    const { inspection } = this

    let inspectorMetadata
    const supervisorMetadata = inspection?.supervisorMetadata

    if (inspection instanceof Inspection) {
      inspectorMetadata = inspection.metadata
    } else if (inspection instanceof InspectionView) {
      inspectorMetadata = inspection.inspectorMetadata
    }

    const mergedMetadata = supervisorMetadata || inspectorMetadata

    mergedMetadata?.categories?.forEach((category, index) => {
      if (category.score === null) {
        mergedMetadata.categories[index].score = inspectorMetadata?.categories?.find(item => item.name === category.name).score
      }
      if (category.supervisorCost === null) {
        mergedMetadata.categories[index].supervisorCost = inspectorMetadata?.categories?.find(item => item.name === category.name).supervisorCost
      }
      if (category.inspectorCost === null) {
        mergedMetadata.categories[index].inspectorCost = inspectorMetadata?.categories?.find(item => item.name === category.name).inspectorCost
      }
    })

    return mergedMetadata
  }

  get categories (): Category[] {
    const { inspectionMetadata } = this

    return inspectionMetadata?.categories?.filter(Boolean)?.filter((category: Category) => category.name !== 'Fotos')
  }

  get commentInspector () {
    const { inspectionMetadata } = this

    return inspectionMetadata?.commentInspector
  }

  get purchaseAmount () {
    const { inspection } = this

    return inspection?.appraisal?.lastAppraisalResponse?.value
  }
  }
